.track-list-container {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.track-list-container h2 {
  margin-top: 20px;
}

.track-list-objects-container {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  gap: 10px;
}

.track-list-object {
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: #FFF;
  background-color: #63666A;
  align-items:space-between;
  justify-content:space-between;
}

.track-list-row {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
  margin-bottom: 5px;
}

.track-list-row-with-columns {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
  margin-bottom: 5px;
}

.track-list-row-columns {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  margin-bottom: 5px;
  width: 100%;
}

.track-list-object h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.list-heading {
  font-weight: bold;
}

.list-column-left-align {
  width: 60px;
  justify-content: flex-start;
}

.list-column-center-align {
  width: 40px;
  justify-content: center;
}

.list-column-right-align {
  width: 90px;
  justify-content: flex-end;
}


@media (max-width: 1020px) {
  .track-list-container {
    width: 740px;
  }


}

@media (max-width: 768px) {
  .track-list-container {
    width: 460px;
  }

  .track-list-objects-container {
  grid-template-columns: 100%;
}

  .track-list-container {
    font-size: 12px;
  }

  .track-list-container h3 {
    font-size: 16px;
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .track-list-container {
    width: 300px;
  }

  .track-list-object {
    width: 290px;
  }

}
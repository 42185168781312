.App {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
}

.result-tables-conatiner {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: pink; */

}

.round-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.results-selector-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #e10600;
}

.results-selector-container img {
  width: 60px;
  height: 60px;
  padding: 10px 20px 10px 10px;
  background-color: white;
}

.results-selector-container button {
  //margin: 10px;
  width: 100px;
  height: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
  //border: none;
  //background-color: #282c34;
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px;
  border: none;
  cursor: pointer;
  background-color: #e10600;
}

.results-selector-container button:hover {
  background-color: #000;
}

.round-selector-container {
  display: inline-block;
  //flex-direction: column;
  position: relative;
  //margin: 10px;
}

.selector-container {
  display: flex;
  flex-direction: row;
}

.selector-container,
.track-title,
.results-row,
.driver-values-container {
  width: 1000px;
}

.results-row {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  height: 25px;
  //border-color: white;
  //border-bottom: 1px solid white;
  margin-bottom: 2px;
  //background-color: #63666A;
  padding-left: 10px;
  border-radius: 5px;
  align-items: center;
  //justify-content: space-between;
}

.row-heading-text {
  font-weight: bold;
}

.grey-background {
  background-color: #63666A;
}

.white-text {
  color: white;
}

.postion-container,
.grid-container,
.stops-container,
.best-time-container {
  display: flex;
  justify-content: flex-start;
  width: 60px;
}

.name-container,
.team-name-container {
  display: flex;
  justify-content: flex-start;
  width: 200px;
}

.team-name-container {
  width: 250px;
}

.best-time-container {
  width: 150px;
}

.purple-text {
  color: #BF40BF;
}

.round-selector-header,
.round-selector-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 14px;
  cursor: pointer;
}

.round-selector-item {
  height: 50px !important;
}

.show-round-list {
  display: block;
  position: absolute;
}

.hide-round-list {
  display: none;
}

.track-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.track-title h2 {
  color: #000;
  margin: 20px 0 10px 0;
}

.track-title h3 {
  color: #000;
  align-self: flex-start;
  margin: 0 0 20px 0;
}

@media (max-width: 1020px) {
  .App {
    padding-bottom: 50px;
  }

  .selector-container,
  .track-title,
  .results-row,
  .result-tables-conatiner,
  .driver-values-container {
    width: 740px;
  }

  .results-row,
  .results-selector-container button {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .selector-container,
  .track-title,
  .results-row,
  .result-tables-conatiner  {
    width: 460px;
  }

  .results-row,
  .results-selector-container button {
    font-size: 12px;
  }

  .team-name-container {
    display: none;
  }
}

@media (max-width: 480px) {
  .selector-container,
  .track-title,
  .results-row,
  .result-tables-conatiner {
    width: 300px;
  }

  .results-row,
  .results-selector-container button {
    font-size: 10px;
  }

  .results-selector-container button {
    width: 60px;
    margin-right: 0;
  }

  .grid-container {
    display: none;
  }

  .stops-container {
    width: 100px;
  }

}

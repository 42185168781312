.driver-values-container {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.driver-values-container h2 {
  margin-top: 20px;
}

.driver-object-container {
  //background-color: pink;
  display: grid;
  //grid-template-columns: repeat(3, 2fr);
  grid-template-columns: 290px 290px 290px;
  gap: 10px;
}

.driver-value-object {
  width: 270px;
  //height: 84px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: #FFF;
  background-color: #63666A;
  align-items:space-between;
  justify-content:space-between;
}

.driver-value-row {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
}

.driver-value-object-info-container {
  display: flex;
  flex-direction: column;
}

.driver-value-object-info-container h3 {
  margin: 0;
}

.driver-value-object-info-container p {
  margin: 0;
  font-size: 16px;
}

.driver-object-value-container {
  display: flex;
  align-items: center;
}

.driver-object-value-container h3 {
  font-size: 25px;
  margin: 0;
}

.driver-round-values-container {
  margin-top: 5px;
  flex-direction: column;
}

.driver-round-value-list-row {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
  margin-top: 5px;
  font-size: 14px;
}

.driver-round-value-list-column {
  display: flex;
  flex-direction: row;
}

.list-heading {
  font-weight: bold;
}

.list-column-left-align {
  width: 60px;
  justify-content: flex-start;
}

.list-column-center-align {
  width: 40px;
  justify-content: center;
}

.list-column-right-align {
  width: 90px;
  justify-content: flex-end;
}

.driver-list-drop-down-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
}

@media (max-width: 1020px) {
  .driver-values-container {
    width: 740px;
  }

  .driver-object-container {
    grid-template-columns: 280px 280px;
  }

  .driver-value-object {
    width: 260px;
  }

  .driver-value-object-info-container p {
    font-size: 14px;
    //width: 110px;
    height: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .driver-values-container {
    width: 460px;
  }

  .driver-object-container {
    grid-template-columns: 220px 220px;
  }

  .driver-value-object {
    width: 200px;
  }

  .driver-value-object-info-container h3,
  .driver-value-object-info-container p {
    font-size: 12px;
  }

  .driver-object-value-container h3 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .driver-values-container {
    width: 300px;
  }

  .driver-object-container {
    grid-template-columns: 290px;
  }

  .driver-value-object {
    width: 290px;
  }

  .driver-value-object-info-container h3,
  .driver-value-object-info-container p,
   {
    font-size: 10px;
  }

  .driver-round-value-list-row {
    font-size: 12px;
  }
}